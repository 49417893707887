<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="公司名称">
                                <el-input v-model="search.company_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="联系人姓名">
                                <el-input v-model="search.company_contacts"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="联系人手机号">
                                <el-input v-model="search.company_contacts_mobile"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    size="medium"
                    border
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="company_name"
                        label="公司名称"
                        width="130">
                    <template slot-scope="scope">
                        {{ scope.row.company_name }}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="company_contacts"
                        label="联系人姓名"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="company_contacts_mobile"
                        label="联系人手机号"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="remarks"
                        label="备注">
                </el-table-column>
                <el-table-column
                        prop="state"
                        label="状态"
                        width="180">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state===1" type="success">正常</el-tag>
                        <el-tag size="mini" v-if="scope.row.state===2" type="warning">停用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="开通时间"
                        width="180">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="130">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('marketing.groupbuy.companydisable') && scope.row.state===1"
                                @click="operation_tip(scope.row,2)"
                                type="warning" size="mini">停用
                        </el-button>
                        <el-button
                                v-if="is_auth('marketing.groupbuy.companyenable') && scope.row.state===2"
                                @click="operation_tip(scope.row,1)"
                                type="primary" size="mini">启用
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>


        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '拼团活动商户列表',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {}
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "marketing.groupbuy.getcompanylist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);

            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(info, enable = '') {

            let tip = ''
            let options = {}
            // 启用
            if (enable === 1) {
                tip = '启用【' + info.company_name + '】拼团活动？'
            }
            // 禁用
            if (enable === 2) {
                tip = '禁用【' + info.company_name + '】拼团活动？'
            }

            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                    .then(() => {
                        this.isoperation(info.company_uuid, enable)
                    })
                    .catch(() => {
                    });
        },
        // 操作
        isoperation(company_uuid = '', enable = '') {
            let postdata = {
                token: this.Tool.get_l_cache('token'),
                company_uuid,
                enable,
            }
            // 启用，禁用
            if (enable === 1) {
                postdata.api_name = 'marketing.groupbuy.companyenable';
            } else if (enable === 2) {
                postdata.api_name = 'marketing.groupbuy.companydisable';
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}

</style>
